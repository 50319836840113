form#iosInput input[type=password]{
  height: 50px;
  width: 40px;
  text-align: center;
  font-size: 2em;
  border: 1px solid #000;
  margin: 2px;
}

form#iosResult input[type=text]{
  height: 50px;
  width: 40px;
  text-align: center;
  font-size: 2em;
  border: 1px solid #000;
  margin: 2px;
}

.boxl {
width: 300px;
max-width: 85%;
margin: 15px auto;
border-radius: 15px;
background: #8e8e93;
color: #fff;
padding: 20px;
text-align: center;
font-weight: 900;
font-family: arial;
position: relative;
}

.boxr {
width: 300px;
max-width: 85%;
margin: 15px auto;
border-radius: 15px;
background: #5fc9f8;
color: #fff;
padding: 20px;
text-align: center;
font-weight: 900;
font-family: arial;
position: relative;
}

.sb1:before {
content: "";
width: 0px;
height: 0px;
position: absolute;
border-left: 15px solid #8e8e93;
border-right: 15px solid transparent;
border-top: 15px solid #8e8e93;
border-bottom: 15px solid transparent;
right: -16px;
top: 0px;
}

.sb2:before {
content: "";
width: 0px;
height: 0px;
position: absolute;
border-left: 15px solid transparent;
border-right: 15px solid #5fc9f8;
border-top: 15px solid #5fc9f8;
border-bottom: 15px solid transparent;
left: -16px;
top: 0px;
}

.flex-wrapper {
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  bottom: 0;
}